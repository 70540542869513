import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  classData: {
    classId: '',
    configId: '',
    teach: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_basic: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_intermediate: {
      completedIndex: [],
      currentIndex: 0,
    },
    coach_advanced: {
      completedIndex: [],
      currentIndex: 0,
    },
  },
  timers: {
    teach: {},
    coach_basic: {},
    coach_intermediate: {},
    coach_advanced: {},
  },
  liveTimer: {
    teach: null,
    coach_basic: null,
    coach_intermediate: null,
    coach_advanced: null,
  },
  activeElement: {
    teach: {},
    coach_basic: {},
    coach_intermediate: {},
    coach_advanced: {},
  },
  activeHtmlSlide: {
    teach: 1,
    coach_basic: 1,
    coach_intermediate: 1,
    coach_advanced: 1,
  },
  activeHtmlStep: {
    teach: 0,
    coach_basic: 0,
    coach_intermediate: 0,
    coach_advanced: 0,
  },
  currentHtmlSlide: {
    teach: 1,
    coach_basic: 1,
    coach_intermediate: 1,
    coach_advanced: 1,
  },
  slideTimers: {
    teach: {},
    coach_basic: {},
    coach_intermediate: {},
    coach_advanced: {},
  },
  slideLiveTimer: {
    teach: {
      slideNo: null,
      timer: 0,
      elementId: null,
      elementName: null,
      elementSlideNo: null,
    },
    coach_basic: {
      slideNo: null,
      timer: 0,
      elementId: null,
      elementName: null,
      elementSlideNo: null,
    },
    coach_intermediate: {
      slideNo: null,
      timer: 0,
      elementId: null,
      elementName: null,
      elementSlideNo: null,
    },
    coach_advanced: {
      slideNo: null,
      timer: 0,
      elementId: null,
      elementName: null,
      elementSlideNo: null,
    },
  },
  currentElementIndex: {
    teach: 0,
    coach_basic: 0,
    coach_intermediate: 0,
    coach_advanced: 0,
  },
  pluginStatus: {
    whiteboard: {
      active: false,
      show: false,
    },
    slide: {
      active: false,
      show: false,
    },
  },
};

const slideStatusSlice = createSlice({
  name: 'slideStatus',
  initialState,
  reducers: {
    setClassData: (state, action) => {
      const { classState, currentIndex, completedIndex, classId, configId } =
        action.payload;
      if (classId && configId) {
        state.classData.classId = classId;
        state.classData.id = configId;
      }
      if (state?.classData?.[classState]) {
        if (completedIndex !== undefined && completedIndex !== null) {
          state?.classData?.[classState]?.completedIndex?.push(completedIndex);
        }
        state.classData[classState].currentIndex = currentIndex;
      }
    },
    setPluginStatus: (state, action) => {
      state.pluginStatus = {
        ...state.pluginStatus,
        ...action.payload,
      };
    },
    setCurrentHtmlSlide: (state, action) => {
      const { classState, currentHTMLSlide } = action.payload;
      state.currentHtmlSlide[classState] = currentHTMLSlide;
    },
    setTimers: (state, action) => {
      const { classState, timer } = action.payload;
      state.timers[classState] = timer;
    },
    setLiveTimer: (state, action) => {
      const { classState, liveTimer } = action.payload;
      state.liveTimer[classState] = liveTimer;
    },
    incrementLiveTimer: (state, action) => {
      const { classState } = action.payload;
      state.liveTimer[classState] =
        state.liveTimer[classState] !== null
          ? state.liveTimer[classState] + 1
          : null;
    },
    setSlideTimers: (state, action) => {
      const { classState, slideTimer } = action.payload;
      state.slideTimers[classState] = slideTimer;
    },
    setSlideLiveTimer: (state, action) => {
      const {
        classState,
        timer,
        slideNo,
        elementId,
        elementName,
        elementSlideNo,
      } = action.payload;
      state.slideLiveTimer[classState].slideNo = slideNo;
      state.slideLiveTimer[classState].timer = timer;
      state.slideLiveTimer[classState].elementId = elementId;
      state.slideLiveTimer[classState].elementName = elementName;
      state.slideLiveTimer[classState].elementSlideNo = elementSlideNo;
    },
    incrementSlideLiveTimer: (state, action) => {
      const { classState } = action.payload;
      if (state.slideLiveTimer[classState].timer === null) {
        state.slideLiveTimer[classState].timer = 0;
      }
      state.slideLiveTimer[classState].timer += 1;
    },

    setActiveHtmlSlide: (state, action) => {
      const { classState, activeHTMLSlide } = action.payload;
      state.activeHtmlSlide[classState] = activeHTMLSlide;
    },
    setActiveHtmlStep: (state, action) => {
      const { classState, activeHTMLStep } = action.payload;
      state.activeHtmlStep[classState] = activeHTMLStep;
    },
    setActiveElement: (state, action) => {
      const { classState, element } = action.payload;
      state.activeElement[classState] = element;
    },
    setCurrentElementIndex: (state, action) => {
      const { classState, elementIndex } = action.payload;
      state.currentElementIndex[classState] = elementIndex;
    },
    resetData: (state) => {
      state.timers = initialState.timers;
      state.liveTimer = initialState.liveTimer;
      state.slideTimers = initialState.slideTimers;
      state.slideLiveTimer = initialState.slideLiveTimer;
      state.classData = initialState.classData;
      state.activeElement = initialState.activeElement;
      state.activeHtmlSlide = initialState.activeHtmlSlide;
      state.activeHtmlStep = initialState.activeHtmlStep;
      state.currentHtmlSlide = initialState.currentHtmlSlide;
      state.currentElementIndex = initialState.currentElementIndex;
      state.pluginStatus = initialState.pluginStatus;
    },
  },
});

export const {
  setClassData,
  incrementLiveTimer,
  setTimers,
  setCurrentElementIndex,
  setLiveTimer,
  setSlideTimers,
  setSlideLiveTimer,
  incrementSlideLiveTimer,
  resetData,
  setActiveElement,
  setActiveHtmlStep,
  setActiveHtmlSlide,
  setCurrentHtmlSlide,
  setPluginStatus,
} = slideStatusSlice.actions;

export const getCurrentHtmlSlide = (state) =>
  state.SlideStatus.currentHtmlSlide;
export const getTimers = (state) => state.SlideStatus.timers;
export const getLiveTimer = (state) => state.SlideStatus.liveTimer;
export const getSlideTimers = (state) => state.SlideStatus.slideTimers;
export const getSlideLiveTimer = (state) => state.SlideStatus.slideLiveTimer;
export const getClassData = (state) => state.SlideStatus.classData;
export const getActiveElement = (state) => state.SlideStatus.activeElement;
export const getActiveHtmlSlide = (state) => state.SlideStatus.activeHtmlSlide;
export const getActiveHtmlStep = (state) => state.SlideStatus.activeHtmlStep;
export const getCurrentElementIndex = (state) =>
  state.SlideStatus.currentElementIndex;
export const getPluginStatus = (state) => state.SlideStatus.pluginStatus;

export default slideStatusSlice.reducer;
