import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { isEmpty, find, startCase } from 'lodash';

import { primaryLoginAuthenticationToken } from 'slices/student-guardian/auth/login/reducer';
import { getProfileInformationRedux } from 'slices/student-guardian/account/profile/reducer';
import { logout } from 'slices/student-guardian/auth/login/thunk';

import { logoutAdmin } from 'slices/admin/auth/thunk';
import { resetAllProfileDetails } from 'slices/student-guardian/account/profile/thunk';
import { capitalizeFirstLetter } from 'helpers/common';
import { base } from 'config';
import { getListOfRoles } from 'slices/utils/thunk';
import { getRolesList } from 'slices/utils/reducer';
import Cookie from 'js-cookie';
import { getUserDetails, getLoginStatus } from 'slices/admin/auth/reducer';

//import images
import avatar1 from 'assets/images/users/user-dummy-img.png';
import { datadogRum } from '@datadog/browser-rum';

const ProfileDropdown = (props) => {
  const [roleName, setRoleName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const dispatch = useDispatch();

  const authUserInfo = useSelector(getProfileInformationRedux);
  const adminUser = useSelector(getUserDetails);
  const isLoggedIn = useSelector(getLoginStatus);
  const getRolesListRedux = useSelector(getRolesList);
  useEffect(() => {
    dispatch(getListOfRoles());
  }, []);

  useEffect(() => {
    if (isLoggedIn && !isEmpty(adminUser)) {
      setIsAdmin(true);
      setAdminData(adminUser);
    }
  }, [isLoggedIn, adminUser]);

  useEffect(() => {
    if (!isEmpty(getRolesListRedux) && isAdmin && adminData) {
      const userRole = find(getRolesListRedux, {
        id: adminData?.roleId,
      });
      if (userRole?.name) {
        setRoleName(startCase(userRole.name));
      }
    }
  }, [getRolesListRedux, isAdmin, adminData]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const logoutAccount = () => {
    localStorage.clear();
    dispatch(resetAllProfileDetails());
    dispatch(logout());
    dispatch(logoutAdmin());
    datadogRum.clearUser();
  };

  const getUserRole = (userType = '') => {
    if (userType === base?.USER_TYPES?.STUDENT) {
      return 'Student';
    } else if (userType === base?.USER_TYPES?.GUARDIAN) {
      return 'Parent';
    } else {
      return '';
    }
  };

  const checkAdminPath = useCallback(() => {
    if (isAdmin && adminData) {
      return {
        status: true,
        data: adminData,
      };
    }
    return {
      status: false,
      data: null,
    };
  }, [isAdmin, adminData]);

  const checkIsProfileEnabled = useCallback(() => {
    //Currently Profile page is only built for Student & Guardian
    // TODO: enable profile page for every role
    const isAdminPath = checkAdminPath();
    let userType = '';
    if (isAdminPath?.status) {
      userType = isAdminPath?.data?.roleId;
    } else {
      userType = authUserInfo?.userType;
    }
    if (
      [base?.USER_TYPES?.GUARDIAN, base?.USER_TYPES?.STUDENT].includes(userType)
    )
      return true;
    return false;
  }, []);

  const getLoggedInUserNameAndRole = useCallback(() => {
    const isAdminPath = checkAdminPath();
    if (isAdminPath?.status) {
      return {
        name: isAdminPath?.data?.firstName ? isAdminPath?.data?.firstName : '',
        role: roleName,
        profileImage: isAdminPath?.data?.profileImage
          ? isAdminPath?.data?.profileImage
          : '',
      };
    } else {
      return {
        name: authUserInfo?.firstName
          ? capitalizeFirstLetter(authUserInfo?.firstName)
          : '',
        role: authUserInfo?.userType ? getUserRole(authUserInfo?.userType) : '',
        profileImage: isAdminPath?.data?.profileImage
          ? isAdminPath?.data?.profileImage
          : '',
      };
    }
  }, [authUserInfo, roleName, checkAdminPath]);

  const loggedInUserInfo = getLoggedInUserNameAndRole();
  const isProfileEnabled = checkIsProfileEnabled();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <img
              className={`rounded-circle header-profile-user  ${loggedInUserInfo?.profileImage ? 'profileImage' : ''}`}
              src={loggedInUserInfo?.profileImage || avatar1}
              alt={loggedInUserInfo?.name || ''}
            />
            <span className="w-80 text-start">
              <span className="d-xl-inline-block ms-1 fw-medium user-name-text">
                <span className="d-xl-inline-block ms-1 fw-medium user-name-text">
                  {loggedInUserInfo?.name || ''}
                </span>
                <br />
                <span className="mb-0 fs-10 text-muted">
                  {loggedInUserInfo?.role || ''}
                </span>
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {loggedInUserInfo?.name || ''}!
          </h6>
          {isProfileEnabled ? (
            <DropdownItem href={process.env.PUBLIC_URL + '/account/profile'}>
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </DropdownItem>
          ) : null}
          {/*<DropdownItem href={process.env.PUBLIC_URL + "/account/help"}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </DropdownItem>*/}
          <div className="dropdown-divider"></div>
          <DropdownItem onClick={(e) => logoutAccount(e)}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
