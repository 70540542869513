import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  studentNotes: [],
  errors: null,
  classConfiguration: {},
  loading: false,
  startDyteRecording: {},
  huddleRooms: {},
  huddleStudentsName: [],
};

const inClassSlice = createSlice({
  name: 'InClass',
  initialState,
  reducers: {
    setStudentNotes: (state, action) => {
      state.studentNotes = action.payload;
      state.loading = initialState.loading;
    },
    catchErrors: (state, action) => {
      state.errors = action.payload;
      state.loading = initialState.loading;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setClassConfiguration: (state, action) => {
      state.classConfiguration = action.payload;
    },
    setStartDyteRecording: (state, action) => {
      state.startDyteRecording = action.payload;
    },
    setHuddleRooms: (state, action) => {
      state.huddleRooms = action.payload;
    },
    setHuddleStudentsName: (state, action) => {
      state.huddleRooms = action.payload;
    },
  },
});

export const getStudentNotesRedux = (state) =>
  state?.InClass?.studentNotes || [];
export const catchErrorsInClassRedux = (state) =>
  state?.InClass?.errors || null;
export const getInClassLoaderRedux = (state) =>
  state?.InClass?.loading || false;
export const getClassConfigurationRedux = (state) =>
  state.InClass.classConfiguration;
export const getStartDyteRecording = (state) =>
  state.InClass.startDyteRecording;
export const getHuddleRooms = (state) => state.InClass.huddleRooms;
export const getHuddleStudentsName = (state) =>
  state.InClass.huddleStudentsName;

export const {
  setStudentNotes,
  catchErrors,
  setLoader,
  setClassConfiguration,
  setStartDyteRecording,
  setHuddleRooms,
  setHuddleStudentsName,
} = inClassSlice.actions;

export default inClassSlice.reducer;
