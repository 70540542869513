import { APIClient } from './api_helper';
import { prepareQueryParams } from './auth';
import {
  CURRENT_STATE,
  EVALUATION,
  EVALUATION_SUMMARY,
  LIST_OF_PRACTICE_SHEET_V2,
  RAW_ANSWER,
  UPDATE_NOTEBOOK_EVALUATION,
} from './url_helper';

const api = new APIClient();

export const getEvaluation = ({ id }, headerInfo = '') => {
  return api.get(EVALUATION + `/${id}`, headerInfo);
};

export const postEvaluation = (data, headerInfo = '') => {
  return api.create(RAW_ANSWER, data, headerInfo);
};

export const getCurrentState = ({ classId }, headerInfo = '') => {
  return api.get(`${CURRENT_STATE}?classId=${classId}`, headerInfo);
};

export const getEvaluationSummary = (data, headerInfo = null) => {
  const apiUrl = `${EVALUATION_SUMMARY}${data.classId}/evaluation-summary/`;

  return api.get(apiUrl, { params: data });
};

export const getEvaluationCsv = async (data, headerInfo = null) => {
  const apiUrl = `${EVALUATION_SUMMARY}${data.studentId}/evaluation-summary/export/`;

  try {
    const response = await api.get(apiUrl, {
      params: data,
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const studentName = data.studentName.replace(/\s+/g, '_');
    link.href = url;
    link.setAttribute('download', `evaluation_summary_${studentName}.csv`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting data:', error);
  }
};

export const updateNotebookEvaluation = (data, headerInfo = '') => {
  return api.update(UPDATE_NOTEBOOK_EVALUATION, data, headerInfo);
};

export const postCroppedAnswers = (data, headerInfo = '') => {
  return api.create(`v2/note-answers`, data, headerInfo);
};

export const uploadAnnotatedAnswer = (data, headerInfo = '') =>
  api.update(`v2/note-answers/annotate`, data, headerInfo);

export const getCroppedAnswers = (params) => {
  const paramsString = prepareQueryParams(params);
  return api.get(`v2/note-answers${paramsString}`);
};

export const updateReviewSubmissionStatus = (data, headerInfo = '') => {
  const url = `${LIST_OF_PRACTICE_SHEET_V2}/${data?.practiceSheetId}/evaluations/${data?.evaluationId}/submit`;
  return api.update(url, data, headerInfo);
};
