import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// List Of Scheduled Classes (Student/Guardians)
export const listOfScheduledClassesService = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_SCHEDULED_CLASSES + queryParams;

  return api.get(apiUrl, headerInfo);
};

//List Class V2 API
export const listClassV2 = (query = {}) => {
  let apiUrl = url.CLASS_DETAILS_V2;
  return api.get(apiUrl, { params: query });
};

export const classStatusChange = (data, classId, headerInfo = '') => {
  let apiUrl = url.CLASS_DETAILS_V2 + classId;
  return api.put(apiUrl, data, headerInfo);
};

// Class Details
export const getClassDetailsService = (id, headerInfo = '') => {
  let apiUrl = url.CLASS_DETAILS + id;
  return api.get(apiUrl, headerInfo);
};

// List Of Demo Classes (Student/Guardians)
export const listOfDemoClassesService = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_DEMO_CLASSES + queryParams;
  return api.get(apiUrl, headerInfo);
};
// List Of Demo Class Configurations (Student/Guardians)
export const listOfDemoClassConfigurationsService = (
  data = {},
  headerInfo = '',
) => {
  return api.get(url.LIST_OF_DEMO_CLASS_CONFIGURATIONS, headerInfo);
};

// Join/Apply for the Demo Classes (Student/Guardians)
export const BookDemoClassService = (data, headerInfo = '') =>
  api.create(url.BOOK_OR_APPLY_FOR_DEMO_CLASS, data, headerInfo);

export const prepareQueryParams = (data = '') => {
  let queryParams;
  if (data) {
    queryParams = new URLSearchParams(data)?.toString();
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
  }

  return queryParams;
};

export const createClass = (data, headerInfo = '') =>
  api.create(url.CREATE_CLASS, data, headerInfo);

export const updateClass = (data, headerInfo = '') =>
  api.put(url.UPDATE_CLASS, data, headerInfo);

export const deleteClass = (data, headerInfo = '') =>
  api.delete(url.DELETE_CLASS + data, headerInfo);

export const getJoinClassroomTokenService = (id, headerInfo = '') => {
  let apiUrl = url.JOIN_CLASSROOM_TOKEN + id;
  return api.get(apiUrl, headerInfo);
};

// List Of Notebooks (Via Subtopics)
export const getNotebookViaSubTopics = (data = {}, headerInfo = '') => {
  let queryParams;
  if (data && typeof data === 'object') {
    queryParams = prepareQueryParams(data);
  }
  let apiUrl = url.LIST_OF_NOTEBOOKS_VIA_SUBTOPICS + queryParams;
  return api.get(apiUrl, headerInfo);
};

export const getClassConfiguration = (classId, headerInfo = '') => {
  return api.get(`${url.CLASS_CONTENT}/${classId}`, headerInfo);
};

export const getLectureConfiguration = (classId, headerInfo = '') => {
  return api.get(`${url.CLASS_LECTURE}/${classId}`, headerInfo);
};

export const createClassV2 = (data) => {
  return api.create(url.LIST_OF_CLASSES_V2, data);
};

export const updateClassV2 = (id, data) => {
  return api.put(url.LIST_OF_CLASSES_V2 + `/${id}`, data);
};

export const createPracticeClass = (data) => {
  return api.create(`${url.LIST_OF_CLASSES_V2}/practice-classes`, data);
};

export const createPracticeSheet = (data) => {
  return api.create(`${url.LIST_OF_PRACTICE_SHEET_V2}`, data);
};

export const getPracticeEvaluation = (
  grade,
  board,
  subject,
  studentId,
  headerInfo = '',
) => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/notebook-practice-evaluation?grade=${grade}&board=${board}&studentId=${studentId}&subject=${subject}`,
    headerInfo,
  );
};

export const getPracticeSheet = async (queryString) => {
  const apiUrl = `${url.LIST_OF_PRACTICE_SHEET_V2}?${queryString}`;
  return api.get(apiUrl);
};

export const updatePracticeClass = (id, data) => {
  return api.put(url.LIST_OF_CLASSES_V2 + `/practice-classes/${id}`, data);
};

export const updatePracticeSheet = (data, id) => {
  return api.update(url.LIST_OF_PRACTICE_SHEET_V2 + `/${id}`, data);
};

export const getPracticeClassPreview = (classId, headerInfo = '') => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/practice-classes/${classId}/preview`,
    headerInfo,
  );
};

export const getStudentsByParams = (batchId, headerInfo = '') => {
  return api.get(
    `/${url.LIST_OF_STUDENT_V2}`,
    {
      params: { 'filter[batchId]': batchId },
    },
    headerInfo,
  );
};

export const postElementStatus = (data) => {
  return api.create(url.CLASS_STATUS, data);
};

export const getAssistantLink = (batchId, headerInfo = '') => {
  return api.get(
    `${url.LIST_OF_CLASSES_V2}/getAssistantLink?batchId=${batchId}`,
    headerInfo,
  );
};

export const downloadRecording = (classId, recordingUrl = '') => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/recording/download?recordingUrl=${recordingUrl}`;
  return api.get(urlWithParams);
};

export const downloadRawAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/raw/export`;
  return api.get(urlWithParams);
};

export const downloadVideoAnalyticsAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/video-analytics/export`;
  return api.get(urlWithParams);
};

export const downloadCalculatedAttentionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/attention/calculated/export`;
  return api.get(urlWithParams);
};

export const downloadEmotionData = (classId) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/emotion/export`;
  return api.get(urlWithParams);
};

export const downloadClassSummary = (classId) => {
  const urlWithParams = `/v2/reports/class-summaries/export/${classId}`;
  return api.get(urlWithParams);
};

export const submitTutorFeedback = (data) => {
  return api.create(url.SUBMIT_TUTOR_FEEDBACK, data);
};

export const getFeedbackById = (classId) => {
  const urlWithParams = `${url.GET_FEEDBACK_BY_ID}/${classId}`;
  return api.get(urlWithParams);
};

export const getNotebookEvaluation = (id, params) => {
  const paramsString = prepareQueryParams(params);
  const urlWithParams = `v2/classes/evaluations/${id}${paramsString}`;
  return api.get(urlWithParams);
};

export const getUpdatedSummaryDetails = (queryParams) => {
  const urlWithParams = `${url.LIST_OF_EVALUATIONS}`;
  return api.get(urlWithParams, { params: queryParams });
};

export const getElementBasedNbCaptures = (classId) => {
  const urlWithParams = `v2/classes/evaluations/${classId}/teach-phase-nb-capture`;
  return api.get(urlWithParams);
};

export const getClassFeedback = (params, classId) => {
  let apiUrl = `${url.LIST_OF_FEEDBACKS}/${classId}`;
  return api.get(apiUrl, { params });
};

export const getSimplifiedQuestion = (params) => {
  let apiUrl = url.QC_FILTER_STEP;
  return api.get(apiUrl, { params });
};

export const getQcFilters = async (params) => {
  let apiUrl = url.QC_GAP_FILTER;
  return api.get(apiUrl, { params });
};

export const getQcReport = async (params) => {
  let apiUrl = url.QC_GAP_SUMMARY_LIST;
  return api.get(apiUrl, { params });
};

export const getQcStepReport = async (params) => {
  let apiUrl = url.QC_GAP_STEPS;
  return api.get(apiUrl, { params });
};
