import {
  getPracticeSheetStatus,
  getStudentNotesService,
  sendMeetingTranscripts,
  sendStudentClassNote,
  startDyteRecording,
} from 'helpers/inClass';
import {
  catchErrors,
  setClassConfiguration,
  setLoader,
  setStudentNotes,
  setStartDyteRecording,
  setHuddleRooms,
} from './reducer';
import { getClassConfiguration } from 'helpers/classes';
import { getHeadersPayload } from 'helpers/common';

export const sendMeetingTranscriptsService = (dataTopost) => async () => {
  try {
    const { data, status } = await sendMeetingTranscripts(dataTopost);
    // console.log('---list of scheduled classes--', data);
    status === 200 && console.log('transcripts send successfully');
  } catch (error) {
    console.log('error in send meeting transcripts service', error);
  }
};

export const sendStudentClassNoteService = (dataToPost) => async (dispatch) => {
  try {
    const { status } = await sendStudentClassNote(dataToPost);
    status === 200 && dispatch(getStudentNotes(dataToPost.studentId));
  } catch (error) {
    console.log('error in send class notes service', error);
  }
};

export const getStudentNotes =
  (id = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data, status, message } = await getStudentNotesService(id);
      if (status === 200) {
        dispatch(setStudentNotes(data));
      } else {
        dispatch(catchErrors({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getClassConfigurationForStudent =
  (classId, accessToken) => async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { data, status } = await getClassConfiguration(classId, payload);
      if (status === 200) {
        dispatch(setClassConfiguration(data));
      }
      dispatch(setLoader(false));
    } catch (e) {}
  };

export const startRecordingService = (classId, params) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { status } = await startDyteRecording(classId, params);
    status === 200 && dispatch(setStartDyteRecording(status));
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
  }
};

export const setTheHuddleRooms = (data) => (dispatch) => {
  const areRoomsEmpty = Object.values(data).every((room) => room.length === 0);
  if (!areRoomsEmpty) {
    dispatch(setHuddleRooms(data));
  }
};
