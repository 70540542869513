import { toast } from 'react-toastify';

export const successToastify = (message = '', customPosition = 'top-right') => {
  toast.success(message, { autoClose: 5000, position: customPosition });
};

export const errorToastify = (message = '', customPosition = 'top-right') => {
  toast.error(message, { autoClose: 5000, position: customPosition });
};

export const infoToastify = (message = '', customPosition = 'top-right') => {
  toast.info(message, { autoClose: 5000, position: customPosition });
};

export const warningToastify = (message = '', customPosition = 'top-right') => {
  toast.warning(message, { autoClose: 5000, position: customPosition });
};

export const messageNotificationToast = (
  message = '',
  icon = '',
  customPosition = 'top-right',
) => {
  toast.info(message, {
    autoClose: 5000,
    position: customPosition,
    theme: 'colored',
    icon: icon ? icon : '💬',
  });
};
