module.exports = {
  google: {
    API_KEY: '',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
  api: {
    API_BASE_URL: process.env.REACT_APP_API_URL,
    API_DOMAIN_URL: process.env.REACT_APP_BASE_URL_BACKEND,
  },
  base: {
    DOMAIN_ENV: process.env.REACT_APP_DOMAIN_ENV,
    WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
    SITE_NAME: process.env.REACT_APP_SITE_NAME,
    USER_TYPES: {
      STUDENT: 'student',
      GUARDIAN: 'guardian',
      SUPERADMIN: 1,
      ADMIN: 2,
      CURRICULUM_MANAGER: 3,
      TUTOR: 4,
      SALES: 5,
    },
    WHATSAPP_AUTH_TOKEN: process.env.REACT_APP_WHATSAPP_AUTH_TOKEN,
    SOCKET_IO_URL: process.env.REACT_APP_SOCKET_IO_URL,
    ROLES: {
      TUTOR: 'TUTOR',
      TUTOR_MANAGER: 'TUTOR_MANAGER',
      ADMIN: 'ADMIN',
      SUPER_ADMIN: 'SUPER_ADMIN',
      CURRICULUM_MANAGER: 'CURRICULUM_MANAGER',
      SCHOOL_ACCOUNT_MANAGER: 'SCHOOL_ACCOUNT_MANAGER',
    },
    ROOMS_TITLES: {
      TEACH: 'MAIN',
      COACH_BASIC: 'FOUNDATION',
      COACH_INTERMEDIATE: 'INTERMEDIATE',
      COACH_ADVANCED: 'ADVANCED',
    },
    ROOMS_IDS: {
      PRACTICE: 'practice',
      TEACH: 'teach',
      COACH_BASIC: 'coach_basic',
      COACH_INTERMEDIATE: 'coach_intermediate',
      COACH_ADVANCED: 'coach_advanced',
    },
    RECORDING_DISABLED_FOR_TUTORS:
      process.env.REACT_APP_DISABLE_RECORDING_FOR_TUTOR_IDS || '',
    MIN_NETWORK_SCORE: process.env.REACT_APP_MIN_NETWORK_SCORE || '6',
    SANITY_PREVIEW_BASE_URL: process.env.SANITY_PREVIEW_BASE_URL 
  },
  morphCast: {
    API_KEY: process.env.REACT_APP_LICENSE_KEY,
    BACKEND: process.env.REACT_APP_VIDEO_ANALYTICS_BE,
    ATTENTION_INTERVAL: process.env.REACT_APP_ATTENTION_CAPTURE_INTERVAL,
    INITIAL_WAIT: process.env.REACT_APP_ATTENTION_INITIAL_WAIT,
    MAX_INPUT_FRAME_SIZE: process.env.REACT_APP_MORPH_MAX_INPUT_FACE_SIZE,
    ACTIVE_STATUS: process.env.REACT_APP_MORPHCAST_ACTIVE === 'true',
  },
  dytePlugins: {
    DOCUSHARE: process.env.REACT_APP_DOCUSHARE_PLUGIN_ID,
    WHITEBOARD: process.env.REACT_APP_WHITEBOARD_PLUGIN_ID,
    RECORDING_APP: process.env.REACT_APP_RECORDING_APP_URL,
  },
  dyteParticipantPresetId: {
    GROUP_CALL_VIEWER_PRESET_ID: process.env.REACT_APP_GROUP_CALL_VIEWER_ID,
  },
  payment: {
    AGGREGATOR: process.env.REACT_APP_PAYMENT_AGGREGATOR
      ? process.env.REACT_APP_PAYMENT_AGGREGATOR.split(',')
      : [],
  },
};
