import { APIClient } from './api_helper';
import * as url from './url_helper';

const api = new APIClient();

// Send Meeting Transcripts
export const sendMeetingTranscripts = (data, headerInfo = '') =>
  api.create(url.SEND_MEETING_TRANSCRIPTS, data, headerInfo);

// Send Student Class Note
export const sendStudentClassNote = (data, headerInfo = '') =>
  api.create(url.SEND_STUDENT_CLASSNOTE, data, headerInfo);

// Get Student Notes
export const getStudentNotesService = (id, headerInfo = '') => {
  let apiUrl = url.LIST_OF_STUDENT_NOTES + id;
  return api.get(apiUrl, headerInfo);
};

export const startDyteRecording = (classId, query = {}) => {
  const urlWithParams = `${url.CLASSES_V2}/${classId}/recording?meetingId=${query?.meetingId}`;
  return api.create(urlWithParams);
};

export const updateSession = (data, headerInfo = '') => {
  return api.create(url.UPDATE_SESSION_IS, data, headerInfo);
};

export const getPracticeSheetStatus = async (queryString) => {
  const apiUrl = `${url.LIST_OF_PRACTICE_TRACKER}?${queryString}`;
  return api.get(apiUrl);
};
