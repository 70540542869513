import { coachArrays } from 'pages/Constants';
import { setSlideTimers, setTimers } from './reducer';

export const setSlideTimerData = (lectureData) => async (dispatch) => {
  try {
    const processClassContent = (data) => {
      const result = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };

      function processArray(array, targetObject) {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        if (array.some((item) => item.type !== 'evaluation')) {
          let slideCounter = 1;
          array
            .filter((item) => item.type !== 'evaluation')
            .forEach((item) => {
              if (item.thumbnails && Array.isArray(item.thumbnails)) {
                item.thumbnails.forEach((thumbnail) => {
                  const processedItem = {
                    elementId: item?.elementDetails?.elementId,
                    elementName: item?.elementDetails?.name,
                    elementSlideNo: thumbnail.slideNo,
                    slideNo: slideCounter,
                    timer: 0,
                  };
                  targetObject[slideCounter++] = processedItem;
                });
              } else {
                console.warn(
                  'Expected an array of thumbnails, but got:',
                  item.thumbnails,
                );
              }
            });
        } else {
          console.log('All items are evaluations');
          return;
        }
      }

      if (Array.isArray(data.teach)) {
        processArray(data.teach, result.teach);
      }

      coachArrays.forEach((coachArray) => {
        if (Array.isArray(data[coachArray])) {
          processArray(data[coachArray], result[coachArray]);
        }
      });

      return result;
    };

    const result = processClassContent(lectureData);

    Object.keys(result).forEach((phase) => {
      dispatch(
        setSlideTimers({
          classState: phase,
          slideTimer: result[phase],
        }),
      );
    });
  } catch (err) {
    console.log('error in setting slide timers', err);
  }
};

export const setTimerData = (lectureData) => async (dispatch) => {
  try {
    const processClassContent = (data) => {
      const result = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };

      function processArray(array, targetObject) {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        let elementCounter = 0;
        array.forEach((item) => {
          const processedItem = {
            slideType: item?.type,
            title: item?.elementDetails?.name || item?.title || null,
            timer: 0,
            elementId: item?.elementDetails?.elementId || null,
            evaluationId: item?.evaluationId || null,
            evaluationType: item.evaluationType || null,
            evaluationAt: item?.evaluateAt || null,
          };
          targetObject[elementCounter++] = processedItem;
        });
      }

      if (Array.isArray(data.teach)) {
        processArray(data.teach, result.teach);
      }

      coachArrays.forEach((coachArray) => {
        if (Array.isArray(data[coachArray])) {
          processArray(data[coachArray], result[coachArray]);
        }
      });

      return result;
    };

    const result = processClassContent(lectureData);

    Object.keys(result).forEach((phase) => {
      dispatch(
        setTimers({
          classState: phase,
          timer: result[phase],
        }),
      );
    });
  } catch (err) {
    console.log('error in setting slide timers', err);
  }
};
