import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import { createSelector } from 'reselect';

import { primaryLoginAuthenticationToken } from 'slices/student-guardian/auth/login/reducer';
import {
  getProfileInformationRedux,
  getGuardiansListRedux,
  getSiblingsListRedux,
} from 'slices/student-guardian/account/profile/reducer';

import { checkAdminPath } from 'helpers/common';

//import Components
import StudentsDropdown from 'Components/Common/StudentsDropdown';
import LanguageDropdown from 'Components/Common/LanguageDropdown';
import FullScreenDropdown from 'Components/Common/FullScreenDropdown';
import ProfileDropdown from 'Components/Common/ProfileDropdown';
import LightDark from 'Components/Common/LightDark';

import { changeSidebarVisibility } from 'slices/thunks';
import { base } from 'config';

//import images
import logoSm from 'assets/images/logo-sm.png';
import logoDark from 'assets/images/logo-dark.svg';
import logoLight from 'assets/images/logo-light.svg';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const dispatch = useDispatch();
  const authUserInfo = useSelector(getProfileInformationRedux);
  const authToken = useSelector(primaryLoginAuthenticationToken);
  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype,
  );
  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData);
  const isAdminPath = checkAdminPath();

  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility('show'));

    if (windowSize > 767)
      document.querySelector('.hamburger-icon').classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu');
    }

    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === 'show' &&
      (document.documentElement.getAttribute('data-layout') === 'vertical' ||
        document.documentElement.getAttribute('data-layout') === 'semibox')
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel');
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass} style={{zIndex:50}}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="55" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="55" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex w-100">
              <div className="p-3 w-75"></div>

              {!isAdminPath?.status &&
                authUserInfo?.userType === base?.USER_TYPES?.GUARDIAN && (
                  <StudentsDropdown />
                )}
            </div>

            <div className="d-flex align-items-center">
              {/* LanguageDropdown */}
              {/* <LanguageDropdown /> */}

              {/* FullScreenDropdown */}
              {/* <FullScreenDropdown /> */}

              {/* Dark/Light Mode set */}
              {/* <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              /> */}

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
