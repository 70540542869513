//STUDENT/GUARDIAN REGISTRATION
export const INITIALIZE_REGISTRATION =
  '/auth/student-guardians-temp/request-otp';
export const VERIFY_OTP_REGISTRATION =
  '/auth/student-guardians-temp/verify-otp';
export const RESEND_OTP_REGISTRATION =
  '/auth/student-guardians-temp/request-otp';
export const SAVE_USERDETAILS_REGISTRATION =
  '/auth/student-guardians/registration';
export const SAVE_PASSCODE_REGISTRATION =
  '/auth/student-guardians/set-pass-code';

// dashboard

export const DASHBORD_CARDS = '/student/dashboard-statistics';
export const DASHBORD_SAM_SCHOOL_LIST = 'v2/dashboard/samSchoolDetails';
export const DASHBOARD_CURRICULUM = 'v2/dashboard/curriculum-dashboard';

//STUDENT/GUARDIAN LOGIN
export const VALIDATE_INITIAL_LOGIN =
  '/auth/student-guardians/verify-mobile-number';
export const CHECK_PASSCODE_STATUS =
  '/auth/student-guardians/get-passcode-status';
export const VERIFY_OTP = '/auth/student-guardians-temp/verify-otp';
export const VERIFY_PASSCODE_LOGIN =
  '/auth/student-guardians/login-with-passcode';
export const LIST_OF_ACCOUNTS_LOGIN = '/auth/student-guardians/profile-list';
export const LOGIN_WITH_TEMP_TOKEN = '/auth/student-guardians/login-with-token';

//STUDENT/GUARDIAN FORGOT PASSCODE AND RESET/CREATE NEW PASSCODE
export const VALIDATE_INITIAL_FORGOTPASSCODE =
  '/auth/student-guardians/forgot-passcode';
export const VERIFY_OTP_FORGOTPASSCODE =
  '/auth/student-guardians/get-reset-passcode-token';
export const SAVE_PASSCODE_RESETPASSCODE =
  '/auth/student-guardians/reset-passcode';

//STUDENT/GUARDIAN PROFILE
export const GET_ACCOUNT_DETAILS = '/auth/student-guardians/view-profile';
export const GET_STUDENT = '/auth/student-guardians/get-student';
export const UPDATE_ACCOUNT_DETAILS = '/auth/student-guardians/edit-profile';
export const GET_GUARDIAN_SIBLING_LIST =
  '/auth/student-guardians/sibling-guardians-list';
export const ADD_NEW_GUARDIAN = '/auth/student-guardians/add-profile';
export const ADD_NEW_SIBLING = '/auth/student-guardians/add-profile';
export const VERIFY_NEW_MOBILE_NO =
  '/auth/student-guardians/verify-new-mobile-number';
export const VERIFY_NEW_MOBILE_NO_OTP =
  '/auth/student-guardians/update-mobile-number';
export const INVITE_OR_ADD_GUARDIAN_STUDENT = '/auth/admin/add-new-student';
export const CREATE_STUDENT_GUARDIAN_TEMP = 'v2/auth/add-student-guardian-temp';
export const SEND_WHATSAPP_MESSAGE = '/utility/invite';
export const GET_STUDENT_GUARDIAN_INFO_VIA_ID =
  '/auth/admin/get-student-guardian-detail-via-id';

//BOARD
export const LIST_OF_BOARD = '/board/list';

//SCHOOL
export const LIST_OF_SCHOOL = '/school/list';
export const CREATE_SCHOOL = '/school/create';
export const UPDATE_SCHOOL_NAME = '/school/updateSchoolName';
export const DELETE_SCHOOL = '/school/';
export const GET_SCHOOLS_V2 = 'v2/schools';

//LIST_OF_SCHOOL_CURRICULUM
export const LIST_OF_SCHOOL_CURRICULUM = '/curriculum/';
export const SCHOOL_CURRICULUM_STUDENT_ID = '/curriculum/';
export const CREATE_SCHOOL_CURRICULUM = '/curriculum/';
export const UPDATE_SCHOOL_CURRICULUM = '/curriculum/';
export const DELETE_SCHOOL_CURRICULUM = '/curriculum/';
export const EXPORT_CURRICULUM = '/curriculum/export';
export const IMPORT_CURRICULUM = '/curriculum/import';
export const GET_CURRICULUMS_V2 = 'v2/curriculums/';

//BATCH
export const LIST_OF_BATCHES = '/batches/list';
export const BATCHES_V2 = 'v2/batches';
export const CREATE_BATCH = '/batch/create';
export const UPDATE_BATCH = '/batch/update';
export const DEMO_BATCH_UPDATE = 'v2/batches/demo';

export const DELETE_BATCH = '/batch/';

//CLASS
export const LIST_OF_CLASSES = '/classes/list';
export const LIST_OF_CLASSES_V2 = 'v2/classes';
export const CLASSES_V2 = 'v2/classes';

//EVALUATIONS
export const LIST_OF_EVALUATIONS = '/v2/classes/evaluations';

export const LIST_OF_PRACTICE_SHEET_V2 = '/v2/practice-sheets';
export const LIST_OF_PRACTICE_TRACKER = '/v2/practice-sheets/tracker';
export const LIST_OF_PRACTICE_SUMMARY = '/v2/practice-sheets/summary';

//student/guardian (Admin)
export const LIST_OF_STUDENT_V2 = 'v2/students';
export const LIST_OF_STUDENT = '/student/list';
export const LIST_OF_FAMILY_DETAILS = 'v2/students';
export const STUDENT_GUARDIAN = '/student/';
export const DELETE_STUDENT_GUARDIAN = '/student/delete';
export const GET_SAM_AGAINST_STUDENTS_URL = 'v2/students/sam';

export const LIST_OF_GUARDIAN = '/student/guardian/list';
export const CREATE_GUARDIAN_ADMIN = '/student/guardian/add';
export const UPDATE_GUARDIAN_ADMIN = '/student/guardian/update';

//USER
export const LIST_OF_USERS = '/user/list';
export const CREATE_USER = '/user/create';
export const UPDATE_USER = '/user/update';
export const DELETE_USER = '/user/delete';
//BOOK
export const LIST_OF_BOOKS = '/book/list';
//CHAPTER
export const LIST_OF_CHAPTERS = '/chapter/list';
export const CREATE_CHAPTER = '/chapter/create';
export const UPDATE_CHAPTER = '/chapter/update';
export const DELETE_CHAPTER = '/chapter/delete';
//TOPIC
export const LIST_OF_TOPICS = '/topic/list';
//SUBTOPIC
export const LIST_OF_SUB_TOPICS = '/sub-topic/list';
//SEGMENT
export const LIST_OF_SEGMENTS = '/segment/list';
//ITEM
export const LIST_OF_ITEMS = '/segment/itemsList';
//SUBJECT
export const LIST_OF_SUBJECT = '/subject/list';

//HTML
export const SLIDE_TIMER = '/v2/class-summaries/slide-timer';

//ROLE
export const LIST_OF_ROLE = 'role/list';

//GRADE
export const LIST_OF_GRADE = '/grade/list';

//BATCH TYPE
export const LIST_OF_BATCHE_TYPE = 'batch-type/list';

//CMS CLASS COFIGURATIONS
export const LIST_OF_CLASS_CONFIGURATION = 'v2/configs/class-config';
export const LIST_OF_CONTENT_CONFIGURATION = 'v2/configs/content-config';
export const CREATE_CLASS_CONFIGURATION = 'v2/configs/class-config';
export const CREATE_CONTENT_CONFIGURATION = 'v2/configs/content-config';
export const VALIDATE_CONTENT_CONFIG_URL = 'v2/configs/content-config/validate';

//PRESETS COFIGURATIONS
export const LIST_OF_CONFIGURATION = '/configurations/list';
export const CREATE_CONFIGURATION = '/configurations/create';
export const UPDATE_CONFIGURATION = '/configurations/update';

//CLASSES
/*Demo*/
export const LIST_OF_DEMO_CLASSES = '/demo-classes/list';
export const LIST_OF_DEMO_CLASS_CONFIGURATIONS = '/demo-classes/configurations';
export const BOOK_OR_APPLY_FOR_DEMO_CLASS = '/demo-classes/join';
export const CREATE_DEMO_CLASS_CONFIGURATION = '/demo-classes/configurations';
export const UPDATE_DEMO_CLASS_CONFIGURATION = '/demo-classes/configurations/';
export const LIST_OF_BOOKED_DEMO_CLASS = '/demo-classes/list';
export const UPDATE_DEMO_CLASS = '/demo-classes/';
export const DELETE_DEMO_CLASS = '/demo-classes/';
export const DELETE_DEMO_CLASS_CONFIGURATION = '/demo-classes/configurations/';

/*Scheduled*/
export const LIST_OF_SCHEDULED_CLASSES = '/classes/list';
export const CREATE_CLASS = '/classes/create';
export const UPDATE_CLASS = '/classes/update';
export const DELETE_CLASS = '/classes/';

// Class Details
export const CLASS_DETAILS = '/classes/';
export const CLASS_DETAILS_V2 = 'v2/classes/';

//COMMON/UTILITIES
export const LIST_OF_SCHOOLS_GOOGLEAPIS = '/school/listFromGoogle';
export const LIST_OF_CITIES = '/utility/getCountries';
export const LIST_OF_CONFIGUARATIONS = '/utility/get-configurations';

//JOB APPLICATION
export const GET_API_KEY = '/api-key';

// IN-CLASS TRANSCRIPTS
export const SEND_MEETING_TRANSCRIPTS = '/transcripts/add';

// IN-CLASS CLASS NOTES
export const SEND_STUDENT_CLASSNOTE = '/class-notes/add';
export const LIST_OF_STUDENT_NOTES = '/class-notes/';

// PAYMENTS MODULE
export const LIST_PLANS = '/payment/plans/list';
export const LIST_PRODUCTS = '/payment/products/';
export const CREATE_SUBSCRIPITONS = '/payment/subscriptions/create';
export const VERIFY_VPA = '/payment/subscriptions/verifyVpa';
export const CREATE_PHONEPE_SUBSCRIPITONS =
  '/payment/subscriptions/createPhonepe';

export const ADMIN_LOGIN = 'v2/self';
// JOIN CLASSROOM TOKEN
export const JOIN_CLASSROOM_TOKEN = '/public/join-class/';

// UNDERSTANDING
export const UNDERSTANDING = '/understanding';
export const UNDERSTANDING_CLASS = '/understanding/class';
export const ADD_UNDERSTANDING = '/understanding/add';

// NoteBook
export const ADD_NOTEBOOK_DETAILS = '/notes/add';
export const UPDATE_NOTEBOOK_DETAILS = '/notes/update';
export const GET_LIST_OF_NOTEBOOKS = '/notes/list';
export const LIST_OF_NOTEBOOKS_VIA_SUBTOPICS = '/notes/list-with-subtopicid';

// UTILITY
export const GET_CURRENT_TIME = '/utility/getCurrentTime';

//TALKOMETER
export const TALKOMETER = '/talkometer';

//CallBridge

export const CALLBRIDGE_API_URL = '/call-bridge/voice';
export const CLICK_TO_CALL = '/v2/click-to-call/voice';

//TUTOR DETAILS
export const TUTOR_DETAILS = '/tutors';

//EPIC-SEARCH
export const EPIC_SEARCH = 'v2/epic-search/';

// EVALUATION
export const EVALUATION = 'in-class/class-content/evaluation';
export const RAW_ANSWER = 'v2/in-class/raw-answer/';
export const CURRENT_STATE = 'in-class/engagement/current-state';
export const CLASS_CONTENT = 'in-class/class-content';
export const CLASS_LECTURE = 'v2/configs';
export const CLASS_STATUS = '/in-class/engagement/class-status/';
export const EVALUATION_SUMMARY = '/v2/classes/';
export const UPDATE_NOTEBOOK_EVALUATION = '/v2/classes/evaluations/update-eval';
export const NOTE_PAGES = '/v2/note-pages';

//STUDENT VIEWER URL
export const STUDENT_VIEWER_URL = '/v2/parents/class-token';
export const LIST_OF_SUBSCRIPTIONS = 'v2/subscriptions/';
export const LIST_OF_PAYMENTS = 'v2/subscriptions/payments';

export const UPLOAD_SANITY_ASSETS = 'v2/utilities/sanity/assets/upload';

export const UPDATE_SESSION_IS = 'v2/classes/sessions';
export const SUBMIT_TUTOR_FEEDBACK = 'v2/classes/updateFeedback';
export const APPROVE_TUTOR_FEEDBACK = 'v2/classes/approveFeedback';
export const GET_FEEDBACK_BY_ID = 'v2/classes/getFeedbackById';
export const POST_REPORT = 'v2/reports';
export const LIST_OF_CLASS_SUMMARIES = 'v2/class-summaries/';
export const QC_GAP_SUMMARY_LIST = 'v2/analyze/qc-gap-summary';
export const QC_GAP_STEPS = 'v2/analyze/qc-gap-steps';
export const QC_GAP_FILTER = 'v2/analyze/qc-summary-filters';
export const QC_FILTER_STEP = 'v2/analyze/simplified-description';
export const QC_GAP_REPORT = 'v2/reports/qc-gap';
export const LIST_OF_FEEDBACKS = 'v2/classes/feedbacks';
export const QC_GAP_BATCH_SUMMARY_LIST = 'v2/analyze/qc-gap-batch-summary';
export const QC_GAP_CHAPTER_SUMMARY_LIST = 'v2/analyze/qc-gap-chapter-summary';
